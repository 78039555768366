<template>
  
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";


export default {
  components: {
    ToastificationContent
  },
  data() {
    return {
      data: {},
    };
  },
  created() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-device.scss";
</style>
